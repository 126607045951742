<template>
  <div>
    <h3 class="text-h6 mb-4 font-weight-bold">
      Create new item
    </h3>

    <v-card class="pa-4 mb-4" color="grey700">
      <h4 class="text-body-1 primary500--text font-weight-bold">
        General item data
      </h4>
      <p class="text-caption">
        This is the base item which stores generic item info.
      </p>

      <v-form @submit.prevent>
        <v-row dense>
          <v-col cols="12" md="6">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Generic name</label>
              <v-text-field
                v-model="forms.item.name" filled dense color="primary500"
                placeholder="iPhone 13 (Blue)"
              />
            </fieldset>
          </v-col>

          <v-col cols="12" md="3">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Brand</label>
              <v-text-field
                v-model="forms.item.brand" filled dense color="primary500"
                placeholder="Apple"
              />
            </fieldset>
          </v-col>

          <v-col cols="12" md="3">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Type</label>
              <v-combobox
                v-model="forms.item.type"
                :items="['object', 'wearable', 'digital']"
                filled clearable dense
                placeholder="object"
              />
            </fieldset>
          </v-col>

          <v-col cols="12">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Item description (md editor soon)</label>
              <v-textarea
                v-model="forms.item.description" hide-details dense color="primary500"
                placeholder="Cinematic mode adds shallow depth..." rows="4"
                filled auto-grow
              />
            </fieldset>
          </v-col>

          <v-col cols="12">
            <v-divider class="my-3" />
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canWithdraw = !forms.item.canWithdraw">
              <v-switch
                :input-value="forms.item.canWithdraw"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Withdrawable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be withdrawn for crypto
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canOrder = !forms.item.canOrder">
              <v-switch
                :input-value="forms.item.canOrder"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Orderable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be shipped to user
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canSell = !forms.item.canSell">
              <v-switch
                :input-value="forms.item.canSell"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Sellable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be sold for balance
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canSwap = !forms.item.canSwap">
              <v-switch
                :input-value="forms.item.canSwap"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Exchangeable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be swapped for other items
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-card class="pa-4 mb-4" color="grey700">
      <h4 class="text-body-1 primary500--text font-weight-bold">
        Item variants
      </h4>
      <p class="text-caption mb-1">
        These extend the base item and are what the user actually receives. Things like colors/sizes all have their own variants.
      </p>
      <p class="text-caption orange--text lh-1-3">
        <b>When to make a new item, not variant</b>: if an item has too many variant combinations like color AND size (blue - large, blue - medium, red - large...) it makes
        more sense to just create a new item called <em>Item (Blue)</em> with size variants.
      </p>

      <v-expansion-panels class="mb-4">
        <v-expansion-panel v-for="(variant, i) in forms.variants" :key="i">
          <v-expansion-panel-header color="grey600" class="font-weight-semibold">
            {{ variant.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey600">
            <v-form class="mb-4" @submit.prevent>
              <v-row dense>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-hover>
                        <template #default="{hover}">
                          <v-img class="grey400" :src="variant.icon" :aspect-ratio="1" contain>
                            <v-fade-transition>
                              <div v-if="!variant.icon || hover" class="full-height item-variant-icon">
                                <v-btn
                                  v-if="variant.icon"
                                  icon small absolute top
                                  right
                                  @click="variant.icon = null"
                                >
                                  <fai :icon="['far', 'times']" class="text-h6" />
                                </v-btn>

                                <FileDropArea
                                  :files="files"
                                  disable-hint
                                  accept="image/*"
                                  class="rounded-0 full-height"
                                  @submit="onVariantImageSubmitted($event, i)"
                                  @rejectedFiles="$toast.error('File type invalid')"
                                >
                                  <span class="mb-2">Drop file or</span>
                                </FileDropArea>
                              </div>
                            </v-fade-transition>
                          </v-img>
                        </template>
                      </v-hover>
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-row dense>
                        <v-col cols="12" md="7">
                          <fieldset>
                            <label class="text-body-2 d-block mb-1">Item name</label>
                            <v-text-field
                              v-model="variant.name" filled dense color="primary500"
                              placeholder="iPhone 13 (Blue) 256GB"
                            />
                          </fieldset>
                        </v-col>

                        <v-col cols="12" md="5">
                          <fieldset>
                            <label class="text-body-2 d-block mb-1">Rarity</label>
                            <v-combobox
                              v-model="variant.rarity"
                              :items="rarities"
                              filled clearable dense
                              placeholder="Rare"
                            />
                          </fieldset>
                        </v-col>

                        <v-col cols="12">
                          <fieldset>
                            <label class="text-body-2 d-block mb-1">Item icon url</label>
                            <v-text-field
                              v-model="variant.icon" filled dense color="primary500"
                              placeholder="Icon url (temporary)" persistent-hint hint="Dont use the image uploader thx"
                            />
                          </fieldset>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-3" />
                </v-col>

                <v-col cols="12">
                  <h4 class="text-body-2 primary500--text font-weight-bold">
                    Item pricing
                  </h4>
                  <p class="text-caption mb-1">
                    Every market needs its own price, missing one disables it in that market.
                  </p>
                </v-col>
              </v-row>
            </v-form>

            <div class="text-right">
              <v-btn
                color="red" class="text-none" small
                @click="forms.variants.splice(i, 1)"
              >
                <fai :icon="['fad', 'trash']" class="mr-2" />
                Delete variant
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="text-right">
        <v-btn
          color="blue600 blue100--text" class="text-none"
          @click="onAddNewVariant"
        >
          <fai :icon="['fas', 'plus']" class="mr-2" />
          Add new variant
        </v-btn>
      </div>
    </v-card>

    <!-- create bot -->
    <section class="mb-6">
      <v-expansion-panels multiple tile>
        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.audience" color="grey600">
            1. Set up a target audience
            <template v-if="finishedSteps.audience" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">{{ audience.total }} users</span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsAudience @update="onAudienceUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.template" color="grey600">
            2. Pick a design template
            <template v-if="finishedSteps.template" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">{{ template.name }}</span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsTemplate @update="onTemplateUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.mapping" color="grey600">
            3. Map template variables
            <span v-if="mapping.templateVariables.length" class="ml-1 text--disabled">
              ({{ mapping.mapped.length }}/{{ mapping.templateVariables.length }} template vars)
            </span>
            <template v-if="finishedSteps.mapping" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">
                  {{ mapping.mapped.length }} variables mapped
                </span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsMapping :template="template" @update="onMappingUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.unsub" color="grey600">
            4. Set an unsubscribe group
            <template v-if="finishedSteps.unsub" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">
                  {{ unsub.name }}
                </span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsUnsub @update="onUnsubUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.misc" color="grey600">
            5. Miscellaneous settings
            <template v-if="finishedSteps.misc" #actions>
              <v-icon color="teal">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsMisc @update="onMiscUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.test" color="grey600">
            6. Send yourself a test email
            <template v-if="finishedSteps.test" #actions>
              <v-icon color="teal">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsTest :loading="test.loading" :valid="canTest" @update="onTestUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>

    <section>
      <div class="text-right">
        <v-btn
          color="green700 green200--text" tile
          depressed :ripple="false"
          large
          :disabled="!valid"
          :loading="loading"
          @click="onSendEmails"
        >
          Send {{ audience.total }} emails
        </v-btn>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FileDropArea from '@/components/FileDropArea'
import AdminEmailsAudience from './components/emails/AdminEmailsAudience'
import AdminEmailsTemplate from './components/emails/AdminEmailsTemplate'
import AdminEmailsMapping from './components/emails/AdminEmailsMapping'
import AdminEmailsUnsub from './components/emails/AdminEmailsUnsub'
import AdminEmailsMisc from './components/emails/AdminEmailsMisc'
import AdminEmailsTest from './components/emails/AdminEmailsTest'

function readFile(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.onerror = reject
    fr.readAsDataURL(file)
  })
}

const VARIANT_FORM_DEFAULT = () => ({
  name: 'New variant',
  rarity: '',
  icon: '',
  prices: {},
  meta: {},
})

export default {
  components: {
    AdminEmailsAudience,
    AdminEmailsTemplate,
    AdminEmailsMapping,
    AdminEmailsUnsub,
    AdminEmailsMisc,
    AdminEmailsTest,
    FileDropArea,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      rarities: ['Legendary', 'Rare'],
      files: [],
      forms: {
        item: {
          name: '',
          brand: '',
          category: '',
          description: '',
          type: '',
          canWithdraw: false,
          canOrder: false,
          canSell: true,
          canSwap: true,
        },
        variants: [VARIANT_FORM_DEFAULT()],
      },
      audience: {
        query: null,
        total: null,
      },
      template: {
        id: null,
        name: null,
      },
      mapping: {
        dirty: false,
        valid: false,
        variables: [],
        templateVariables: [],
        mapped: [],
      },
      unsub: {
        name: null,
        group: null,
        groups: null,
      },
      misc: {
        dirty: false,
      },
      test: {
        loading: false,
        dirty: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    finishedSteps() {
      return {
        audience: !!this.audience.total,
        template: !!this.template.id,
        mapping: this.mapping.dirty && this.mapping.valid,
        unsub: !!this.unsub.group,
        misc: this.misc.dirty,
        test: this.test.dirty,
      }
    },
    valid() {
      return Object.values(this.finishedSteps).every(Boolean)
    },
    canTest() {
      const obj = { ...this.finishedSteps }
      delete obj.test

      return Object.values(obj).every(Boolean)
    },
  },
  watch: {
    files(files) {
      if (files.length) {
        this.files = []
      }
    },
  },
  methods: {
    onAddNewVariant() {
      this.forms.variants.push(VARIANT_FORM_DEFAULT())
    },
    async onVariantImageSubmitted(files, variantIndex) {
      const [file] = files
      if (!file) return

      const variant = this.forms.variants[variantIndex]
      variant.icon = await readFile(file)

      console.log(file, variant)
    },
    onAudienceUpdate(data) {
      this.audience.query = data.query
      this.audience.total = data.total
    },
    onTemplateUpdate(data) {
      this.template.id = data.id
      this.template.name = data.name
      this.template.versionId = data.versionId
    },
    onMappingUpdate(data) {
      this.mapping.dirty = true
      this.mapping.templateVariables = data.templateVariables

      this.mapping.variables = data.variables
      this.mapping.mapped = data.variables.filter(i => Object.values(i).every(Boolean))

      this.mapping.valid = this.mapping.mapped.length === data.variables.length
    },
    onUnsubUpdate(data) {
      this.unsub.name = data.name
      this.unsub.group = data.group
      this.unsub.groups = data.groups
    },
    onMiscUpdate(data) {
      this.misc = {
        ...data,
        dirty: true,
      }
    },
    onTestUpdate(send) {
      if (send) {
        this.onTestEmail()
      }

      this.test.dirty = true
    },
    //
    async onTestEmail() {
      this.test.loading = true

      try {
        const payload = await this.$socket.request('admin.emails.send', {
          query: { steamid: this.user.steamid },
          templateId: this.template.id,
          variables: this.mapping.variables,
          sender: this.misc.sender,
          asm: this.unsub,
          userRewards: this.misc.userRewards,
        })

        console.log(payload)

        this.$toast.info('Test email sent')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.test.loading = false
      }
    },
    async onSendEmails() {
      this.loading = true

      try {
        const payload = await this.$socket.request('admin.emails.send', {
          query: this.audience.query,
          templateId: this.template.id,
          variables: this.mapping.variables,
          sender: this.misc.sender,
          asm: this.unsub,
          userRewards: this.misc.userRewards,
        })

        console.log(payload)

        this.$toast.success('Emails sent')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-variant-icon {
  background: rgba(black, 0.7);
}
</style>
